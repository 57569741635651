.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #2c4e69 !important;
    color: #2c4e69 !important;
}

.MuiFormLabel-root.Mui-focused {
    color: #2c4e69 !important;
}
.MuiButton-root{
    text-transform: capitalize !important;
}

.MuiFormHelperText-root{
    margin: 0px !important;
    padding: 0px !important;
}

.Mui-checked{
    color: #2c4e69 !important;
}

.MuiContainer-root{
    width: 100%;
    margin-left: auto;
    box-sizing: border-box;
    margin-right: auto;
    display: block;
    padding: 0 !important;
}

::-webkit-scrollbar {
    width: 5px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1; 
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888; 
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }